function Bottom() {
  const { title } = useSiteMetadata()

  return (
    <Footer>
      <p>
        &copy; {new Date().getFullYear()} {title}. Site by{` `}
        <Link href="https://www.michaeluloth.com">Michael Uloth</Link>.
      </p>
    </Footer>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: var(--s5);
  width: 100%;
  text-align: center;
  font-size: var(--f1);
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import { Link } from './elements'
import useSiteMetadata from '../data/useSiteMetadata'

export default Bottom
