function Top() {
  const { title } = useSiteMetadata()

  return (
    <Nav>
      <SkipNav href="#main-content" />

      <Inner>
        <Home href="/">{title}</Home>

        <div>
          <About href="/about/">About</About>
          <Contact href="/contact/">Contact</Contact>
        </div>
      </Inner>
    </Nav>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  /* border-bottom: 1px solid var(--black); */
  background-color: hsla(360, 100%, 100%, 100%);
  padding-top: var(--s3);
  padding-bottom: var(--s3);
  /* box-shadow: var(--shadow1); */
`

const Inner = styled.div`
  ${container}
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  transform: translateY(2px);
  text-transform: uppercase;
  line-height: 1;
`

const Home = styled(Link)`
  font-size: var(--f7);
  font-weight: 700;
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
`

const About = styled(Link)`
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
`

const Contact = styled(Link)`
  margin-left: var(--s4);
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import { Link, SkipNav } from './elements'
import useSiteMetadata from '../data/useSiteMetadata'
import { container } from '../styles'

export default Top
