export const icon = css`
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: none;
  fill: currentColor;
`

///////////////////////////////////////////////////////////////////////////////////

import { css } from 'styled-components'
