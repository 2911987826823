function Base({ children }) {
  return (
    <>
      <Metadata
        preload={[
          { href: biancoRegular, as: `font`, type: `font/woff2` },
          { href: biancoBold, as: `font`, type: `font/woff2` }
        ]}
        preconnect={[`https://unpkg.com`]}
      />
      <CustomProperties />
      <Reset />

      <Top />

      <Wrapper>
        {children}
        <Bottom />
      </Wrapper>
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Wrapper = styled.div`
  ${container}
  position: relative;
  min-height: 100vh;
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import Metadata from './Metadata'
import Top from './Top'
import Bottom from './Bottom'
import { CustomProperties, Reset, container } from '../styles'
import '../styles/base/font-face.css'
import biancoRegular from '../fonts/BiancoSansNew.woff2'
import biancoBold from '../fonts/BiancoSansNew-Bold.woff2'

export default Base
