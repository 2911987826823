const CustomProperties = createGlobalStyle`
  :root {
    /* Font Families */
    --bodyFont: 
      'Bianco Sans New',
      system-ui,
      /* macOS 10.11-10.12 */ -apple-system,
      /* Windows 6+ */ Segoe UI,
      /* Android 4+ */ Roboto,
      /* Ubuntu 10.10+ */ Ubuntu,
      /* Gnome 3+ */ Cantarell,
      /* KDE Plasma 5+ */ Noto Sans,
      /* fallback */ sans-serif,
      /* macOS emoji */ "Apple Color Emoji",
      /* Windows emoji */ "Segoe UI Emoji",
      /* Windows emoji */ "Segoe UI Symbol",
      /* Linux emoji */ "Noto Color Emoji";;
    --headingFont: inherit;

    /* Type scale (apply to local variables rather than using directly) */
    --f1: .75rem;
    --f2: .875rem;
    --f3: 1rem;
    --f4: 1.125rem;
    --f5: 1.25rem;
    --f6: 1.5rem;
    --f7: 1.875rem;
    --f8: 2.25rem;
    --f9: 3rem;
    --f10: 3.75rem;
    --f11: 4.5rem;

    /* Line Heights */
    --lh1: 1.2;
    --lh2: 1.5;
    --lh3: 1.7;

    /* Space and Size Scale (margin, padding, width, height, border width) */
    --s1: .25rem;
    --s2: .5rem;
    --s3: .75rem;
    --s4: 1rem;
    --s5: 1.5rem;
    --s6: 2rem;
    --s7: 3rem;
    --s8: 4rem;
    --s9: 6rem;
    --s10: 8rem;
    --s11: 12rem;
    --s12: 16rem;
    --s13: 24rem;
    --s14: 32rem;
    --s15: 48rem;
    --s16: 64rem;
    --s17: 96rem;

    /* Max-widths */
    --measure1: 45ch;
    --measure2: 55ch;
    --measure3: 66ch;
    --measure4: 75ch;

    /* Border radius */
    --r0: 0;
    --r1: .125rem;
    --r2: .25rem;
    --r3: .375rem;
    --r4: .5rem;
    --r5: .75rem;
    --r6: 1rem;
    --r100: 100%;

    /* Colors */
    --black: hsl(210, 4%, 10%);
  }
`

import { createGlobalStyle } from 'styled-components'

export default CustomProperties
